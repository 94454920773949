<div class="d-flex flex-column">
  <label class="label" *ngIf="label"> {{ label }} <span >*</span> </label>

  <div class="custom-dropdown" [ngClass]="{'no-info': !hasInfo}">
    <div class="dropdown-flex">
      <div (click)="toggleDropdown()" class="d-flex" >
        <div class="col-9 pl-0">
          {{ selectedOption || placeholder }} 
        </div>
        <div class="col-3 text-end">
          <img class="text-end" src="assets/images/angle-down.svg" alt="">
        </div>
      </div>
      <ul *ngIf="isDropdownOpen">
          <li *ngFor="let option of options" (click)="onSelect(option)">{{ option }}</li>
      </ul>
    </div>
  </div>
</div>