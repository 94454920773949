<div class="col-12 my-4 px-4 d-flex justify-content-end">
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div class="content d-flex justify-content-center">
    <div class="center">
        <img class="rounded mx-auto d-block mb-5" src="assets\images\icono-captcha-error.svg" alt="">
        <p class="title text-center mb-4">
            !Ups!
        </p>
        <p class="text text-center mx-3">
            Algo inesperado sucedió intentando reenviar email.<br>Por favor intentá de nuevo.
        </p>
    </div>
</div>