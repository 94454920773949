<div class="row">
  <div class="col-12 p-md-1 p-sm-2 p-xs-2">
    <div class="row paso_2 mx-1">
      <div class="col-12 mt-3 text-center logo-mobile">
        <img class="my-3" src="assets/images/telecom.svg" alt="" />
      </div>
    </div>
  </div>
</div>
<div class="row mx-1">
  <div class="mx-auto mt-4 mobile">
    <div class="register-error-content">
      <div class="container-error">
        <div class="box-img">
          <img
            class="img-error"
            src="assets/images/icono-captcha-error.png"
            alt="send email success"
          />
        </div>
        <p class="title-error">¡Ups!</p>
        <div class="box-subt">
          <p class="subtitle-error subtitle-space">
            Algo inesperado sucedió intentando reenviar el email . Por favor
            contactate con el <span class="assesor" (click)="showAsesor()">asesor online</span> para más información.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
