import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Environments, environment } from '../../environments/environment';
import { EnvironmentService } from '../services/environment.service';
import { RegisterService } from '../services/register.service';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css'],
})
export class ActivateUserComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService,
    private cookieService: CookieService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private registerService: RegisterService
  ) {}

  ngOnInit(): void {
    // Detectamos el ambiente
    this.document.body.classList.add(this.virtual ? 'virtual' : 'desktop');
  }

  private get queryParams(): any {
    return this.route && this.route.snapshot && this.route.snapshot.queryParams;
  }

  public get success(): boolean {
    return Boolean(this.queryParams && this.queryParams.success === 'true');
  }

  public get name(): string {
    return this.queryParams && this.queryParams.name;
  }

  public get lastName(): string {
    return this.queryParams && this.queryParams.lastName;
  }

  public get email(): string {
    return this.queryParams && this.queryParams.email;
  }

  public get token(): string {
    return this.queryParams && this.queryParams.token;
  }

  get virtual(): boolean {
    return Environments.Desktop.some((f: string) =>
      this.environmentService.currentEnvironment.includes(f)
    );
  }

  public goHome() {
    const clientId = this.cookieService.get('clientId');
    this.router.navigate(['/'], {
      queryParams: {
        redirectUrl: `${environment.loginUrl}/oauth/oauthcode?clientId=${clientId}`,
      },
    });
  }

  public async activate(): Promise<any> {
    const clientId = this.cookieService.get('clientId');
    this.spinner.show();
    try {
      await this.registerService.activateUser(this.email, this.token, clientId);
      this.spinner.hide();
      this.router.navigate(['/success']);
    } catch (error) {
      this.spinner.hide();
      this.router.navigate(['activateUser'], {
        queryParams: {
          name: error.name,
          lastName: error.lastName,
          success: error.success,
          clientId,
        },
      });
    }
  }
}
