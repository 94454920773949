import {
  Component,
  OnInit,
} from '@angular/core';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from '../services/environment.service';
import { environment } from 'src/environments/environment';
import { ISalesForceInfo } from '../shared/interfaces';

@Component({
  selector: 'app-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
})
export class AppWrapperComponent implements OnInit {
  isLinear = false;
  selectedTab = 0;
  clienteGol = false;
  Emails!: ISalesForceInfo[];

  constructor(public service: RegisterService, private cookieService: CookieService, protected activatedRoute: ActivatedRoute,protected environmentService: EnvironmentService) {}

  ngOnInit() {
    this.Emails = [];
    this.service.isClienteGol.subscribe(data => this.clienteGol = data);
    let clientId = this.cookieService.get('clientId');
    this.clienteGol = clientId === environment.clientId;;
  }

  changeTab(selectedTab: number) {
    if(selectedTab === 1) {
      this.Emails = this.service.contactsSalesForceInfo;
    }
    this.selectedTab = selectedTab;
  }
}
