import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment, Environments } from "src/environments/environment";
import { AuthService } from "../services/auth.service";
import { ForgotPasswordService } from "../services/forgot-password.service";
import { EnvironmentService } from "../services/environment.service";
import { ValidationService } from "../services/validation.service";
import { IUserLogin } from "./interfaces";
import { ReCaptchaV3Service } from "ngx-captcha";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from "../services/toast.service";
import { MetricsService } from "@b2b/gtm-library";
import { Error } from "../models/error";

export interface ILoginMixin {
  loginForm: UntypedFormGroup;
  user: IUserLogin;
  logoSrc?: string;
  bannerIconsSrc?: string;
  captchaToken: string;
  validateCaptcha: boolean;
}
const LIMIT_VALUE = 59;
enum ImagesMsg {
  Success = "assets/images/icono-exito.png",
  Error = "assets/images/icono-captcha-error.png",
  Send = "assets/images/send.svg",
}
export class LoginMixin {
  public tittle: string;
  public description: string;
  public statusResetPassword: boolean;
  public isButtonClicked: boolean = false;
  public showSpinner: boolean = false;
  public showToast: boolean = false;
  public toastError: boolean = false;
  public toastTitle: string = "";
  public toastMessage: string = "";
  public toastType: string = "";
  public forgotResult: string = '';
  public count_send: number = 0;
  public timer: number = 59;
  public limit = 59;
  public showResendMail = false;
  private destroy = new Subject();
  private rxjsTimer = timer(1000, 1000);

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    protected validateService: ValidationService,
    protected activatedRoute: ActivatedRoute,
    protected authService: AuthService,
    protected forgotPasswordService: ForgotPasswordService,
    protected environmentService: EnvironmentService,
    protected cookieService: CookieService,
    protected spinner: NgxSpinnerService,
    protected recaptchaV3Service: ReCaptchaV3Service,
    protected toastService: ToastService,
    protected metricsService: MetricsService
  ) { }

  get queryParams(): any {
    return (
      this.activatedRoute &&
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.queryParams
    );
  }

  get desktop(): boolean {
    return (
      Environments.Desktop.some((f: string) =>
        this.environmentService.currentEnvironment.includes(f)
      ) &&
      !window.location.href.includes("gestion") &&
      !window.location.href.includes("login.fibercorp")
    );
  }

  resolved(this: LoginMixin & ILoginMixin, captchaResponse: string) {
    this.captchaToken = captchaResponse;
  }

  createCookie() {
    this.cookieService.set(
      "clientId",
      this.environmentService.clientId,
      1,
      "/",
      window.location.hostname,
      false,
      "Lax"
    );
  }

  async onLogin(this: LoginMixin & ILoginMixin) {
    this.toastError = false;
    this.showToast = false;
    this.recaptchaV3Service.execute(environment.siteKey, "login", (token: string) => {
      this.captchaToken = token;

      if (this.captchaToken || !this.validateCaptcha || environment.local) {
        if (this.loginForm.dirty && this.loginForm.valid) {
          const value = {
            password: this.loginForm.controls.password.value,
            username: this.loginForm.controls.username.value,
            captcha: this.captchaToken,
          };
          this.showSpinner = true;
          this.spinner.show();
          this.authService.login(value, this.captchaToken).subscribe(
            (status: boolean) => {
              setTimeout(() => {
                this.showSpinner = false;
                this.spinner.hide();
              }, 10000);

              if (status) {
                this.metricsService.sendSuccessfulLoginMetric();
                sessionStorage.setItem('redirected_from', 'login');

                const index = window.location.href.indexOf("&");

                const queryString = window.location.href.slice(index);

                const redirectUrl =
                  index !== -1
                    ? this.authService.redirectUrl + queryString
                    : this.authService.redirectUrl;
                if (redirectUrl && redirectUrl !== "") {
                  localStorage.clear();
                  window.location.href = redirectUrl;
                } else {
                  this.loginForm.controls["password"].setValue("");
                  this.validateService.presentAlertMensaje({
                    Imagen: ImagesMsg.Error,
                    Subtitulo:
                      "No se ha especificado ninguna URL para hacer el redirect.",
                    Titulo: "URL de redirección inválida",
                  });
                }
              }
            },
            (err: Error) => {
              this.showSpinner = false;
              this.spinner.hide();
              this.loginForm.controls["password"].setValue("");

              this.toastType = err.severity;
              this.toastTitle = err.title;
              this.toastMessage = err.message;
              this.showToast = true;
            }
          );
        } else {
          this.loginForm.controls["password"].setValue("");
          this.toastType = "warning";
          this.toastTitle = "";
          this.toastMessage =
            "¡Ups! Algo inesperado ocurrió, por favor intentá de nuevo más tarde.";
          this.showToast = true;
        }
      } else {
        this.loginForm.controls["password"].setValue("");
        this.validateService.presentAlertMensaje({
          Imagen: ImagesMsg.Error,
          Subtitulo: "Debés completar el captcha",
          Titulo: "Captcha inválido",
        });
      }
    });
  }

  goToRegister() {
    this.router.navigate(["/register"]);
  }
  async forgotPassword(this: LoginMixin & ILoginMixin) {
    this.showToast = false;
    if (this.loginForm.controls["email"].invalid) {
      this.validateService.presentAlertMensaje({
        Imagen: ImagesMsg.Error,
        Subtitulo: "Ingresá un correo correcto",
        Titulo: "Error",
      });
      return;
    }
    this.forgotResult = "Waiting";
    this.isButtonClicked = true;
    this.spinner.show();
    this.authService
      .forgotPassword(this.loginForm.controls["email"].value)
      .subscribe(
        () => {
          this.spinner.hide();
          this.forgotResult = 'Success';
          this.showResendMail = false;
          if (this.count_send > 0) {
            this.toastService.initiate({ title: 'Success', content: 'Email reenviado con éxito' });
          }
          this.count_send += 1;
          this.initTimer();
        },
        (httpErrorResponse: HttpErrorResponse) => {
          this.spinner.hide();
          let subtitulo =
            "Ocurrió un error intentando enviar el correo de activación. Por favor, intentá nuevamente en unos minutos.";
          let titulo = "Error";
          if (httpErrorResponse.status === 404) {
            if (httpErrorResponse.error && httpErrorResponse.error.message) {
              this.toastType = "warning";
              this.toastTitle = "Usuario inexistente";
              this.toastMessage = httpErrorResponse.error.message;
              this.showToast = true;
              this.forgotResult = "";
              return;
            }
          }
          this.forgotResult = "Error";
        }
      );
  }

  sendActivationUserMail(this: LoginMixin & ILoginMixin) {
    if (this.validateCorreo()) {
      this.validateService.setBodyAndShow("Enviando solicitud");
      this.showSpinner = true;
      this.spinner.show();

      this.forgotPasswordService
        .resendInvitationEmail(this.loginForm.controls["username"].value)
        .subscribe(
          (d: any) => {
            this.showSpinner = false;
            this.spinner.hide();

            console.log(d.Message || d.message);
            this.validateService.presentAlertMensaje({
              Imagen: ImagesMsg.Success,
              Subtitulo:
                "Se envió el correo de reactivación con éxito, por favor, revisá tu casilla.",
              Titulo: "¡Éxito!",
              RedirectTo: "/",
            });
          },
          (error) => {
            console.error(error);
            this.showSpinner = false;
            this.spinner.hide();
            this.validateService.presentAlertMensaje({
              Imagen: ImagesMsg.Error,
              Subtitulo: "No se pudo enviar el correo de activación.",
              Titulo: "Error",
            });
          }
        );
    }
  }
  validateCorreo(this: LoginMixin & ILoginMixin): boolean {
    if (!this.loginForm.controls["username"].value) {
      this.validateService.presentAlertMensaje({
        Imagen: ImagesMsg.Error,
        Subtitulo: "Debés completar el usuario.",
        Titulo: "Error",
      });
      return false;
    }
    return true;
  }
  initTimer() {
    this.destroy.next(true);
    this.destroy.complete();
    this.destroy = new Subject();
    this.rxjsTimer = timer(1000, 1000);
    this.rxjsTimer.pipe(takeUntil(this.destroy)).subscribe(val => {
      this.timer = val;
      if (this.timer === LIMIT_VALUE) {
        this.showResendMail = true;
        this.timer = 59;
        this.destroy.next(true);
        this.destroy.complete();
      }
    })
  }
  getTimeToString(value: number): string {
    if (value >= 0 && value < 10) {
      return `0${value}`;
    } else {
      if (value > 0) {
        return value.toString();
      }
    }
    return '00';
  }

}
