import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Environments } from "src/environments/environment";
import { ClientService } from "./client.service";

@Injectable()
export class EnvironmentService extends ClientService {
  constructor(protected router: ActivatedRoute, protected navigator: Router) {
    super(router, navigator);
  }

  public get currentClientId(): string {
    return this.clientIdByCookie || this.clientId;
  }

  public get currentEnvironment(): string[] {
    let currentEnvironmentValue: string[];

    if (Environments.Desktop.includes(this.currentClientId)) {
      currentEnvironmentValue = Environments.Desktop;
    } else if (Environments.Gestion.includes(this.currentClientId)) {
      currentEnvironmentValue = Environments.Gestion;
    } else if (Environments.VRA.includes(this.currentClientId)) {
      currentEnvironmentValue = Environments.VRA;
    } else {
      currentEnvironmentValue = Environments.Netkel;
    }

    return currentEnvironmentValue;
  }

  public validateEnvironmentVRA(): void {
    // Validating for VRA Login
    if (
      this.currentEnvironment.some((e: string) =>
        Environments.VRA.includes(e)
      ) ||
      window.location.href.includes("forgotpasswordVRA")
    ) {
      const queryParams = { ...this.queryParams };
      if (window.location.href.includes("forgotpasswordVRA")) {
        const params = new URLSearchParams(window.location.search);

        queryParams.name = params.get("name");
        queryParams.action = params.get("action");
        queryParams.token = params.get("token");
      }

      this.navigator.navigate(
        [
          window.location.href.includes("forgotpasswordVRA")
            ? "vraForgotPassword"
            : "vraLogin",
        ],
        {
          queryParams,
        }
      );
    }
  }

  public redirectToHome(clientId: string = "") {
    const currentUrl: string =
      window.location.href && window.location.href.toLowerCase();

    if (currentUrl.includes("localhost")) {
      window.location.href = `http://localhost:4200`;
      return;
    }

    window.location.href = `https://${window.location.host}`;
  }
}
