import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  // Google Tag Manager For Production
  const scriptGTMForHeadProd = document.createElement('script');
  scriptGTMForHeadProd.innerHTML = `(function(w,d,s,l,i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-NV9N628');`;

  const scriptGTMForBodyProd = document.createElement('noscript');
  scriptGTMForBodyProd.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV9N628" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.head.appendChild(scriptGTMForHeadProd);
  document.body.appendChild(scriptGTMForBodyProd);
} else {
  // Google Tag Manager For DEV/ TEST / QA
  const scriptGTMForHeadDev = document.createElement('script');
  scriptGTMForHeadDev.innerHTML = `(function (w,d,s,l,i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=cNBmAo3SYecdlgBKMstDCg&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-NV9N628');`;

  const scriptGTMForBodyDev = document.createElement('noscript');
  scriptGTMForBodyDev.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV9N628&gtm_auth=cNBmAo3SYecdlgBKMstDCg&gtm_preview=env-3&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.head.appendChild(scriptGTMForHeadDev);
  document.body.appendChild(scriptGTMForBodyDev);
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));