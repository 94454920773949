<div class="container tyc w-100 p-0" data-ui-section-1="register-three">
      <div class="col-12 mb-4 p-0">
        <h1 class="_tyc justify-content-center p-0">Términos y condiciones</h1>
      </div>
      <div class="col-12 overflow-auto scroll" id="style-scroll">
        Los presentes términos y condiciones (en adelante, los “Términos y Condiciones”) regulan el uso del servicio Gestión Online (en adelante el “Servicio”) y los servicios ofrecidos en él a través de la navegación del sitio de internet https://gestiononline.fibercorp.com.ar (en adelante, el "Sitio") que TELECOM ARGENTINA S.A. inscripta en la Inspección General de Justicia el 13 de julio de 1990, bajo el N° 4570, Libro 108, Tomo “A” de Sociedades Anónimas, con domicilio en General Hornos 690, CABA (en adelante, "TELECOM") pone a disposición de sus clientes del segmento corporativo. Por "Cliente" se entiende al titular de algún servicio de tecnología de la información y las comunicaciones brindado por TELECOM (en adelante el/los "Servicio/os Tic/s") perteneciente al segmento corporativo. Por "Usuario Autorizado" se entiende al usuario mayor de edad autorizado por el titular de los Servicios Tics que ingresa al Sitio en su nombre, lo representa y lo obliga en el marco de lo dispuesto en los presentes Términos y Condiciones (en adelante, el/los “Usuario/s”).
        <h4 class="mt-2">1. Aceptación y Conocimiento de los Términos y Condiciones.</h4>
        Los presentes Términos y Condiciones tienen carácter obligatorio y vinculante. Se aplican a todas las actividades realizadas mediante el Sitio. El uso del Sitio implica el conocimiento y la aceptación de ellos. En caso de no estar de acuerdo con los Términos y Condiciones, el Usuario deberá abstenerse de utilizar el Sitio y/o los servicios ofrecidos a través del mismo.
        <h4 class="mt-2">2. Registración.</h4>
        Los Usuarios pueden navegar libremente por el Sitio, pero deberán estar registrados para poder realizar alguna actividad en el mismo. La registración de los Usuarios se realiza ingresando al Sitio, y no tiene costo alguno. Es obligatorio validar el formulario de registro completando todos los campos con datos válidos y verdaderos, de manera exacta y precisa.
        TELECOM no se responsabiliza por la veracidad o certeza de los datos provistos por los Usuarios. Asimismo, TELECOM se reserva el derecho de suspender temporal o definitivamente a los Usuarios en caso de incumplimiento de los Términos y Condiciones, como así también de rechazar los formularios de registro. Los Usuarios accederán a su cuenta personal (en adelante la "Cuenta") mediante un nombre de Usuario y una clave personal que deberán escoger. Al finalizar, el sistema le enviará de manera confidencial a esa cuenta su nombre de Usuario y la clave personal. El Usuario conoce y acepta que será exclusivamente responsable de adoptar todas las medidas de seguridad necesarias para proteger su nombre de Usuario y clave personal elegida. El Usuario conoce y acepta que su nombre de Usuario y clave personal son privados, secretos e intransferibles. El resguardo de los mismos será responsabilidad exclusiva del Usuario, quedando TELECOM eximida de cualquier tipo de responsabilidad por su divulgación a terceros o uso incorrecto. El uso del Sitio realizado con el nombre de Usuario y clave personal será considerado por TELECOM como válido, legítimo y auténticamente realizado por el Usuario, asumiendo este último las consecuencias del uso del Sitio en su nombre. TELECOM no asume responsabilidad alguna en caso de extravío, robo, hurto, pérdida o mal uso por parte del Usuario o de terceros de su Usuario y Contraseña.
        <h4 class="mt-2">3. Comunicación con los Usuarios.</h4>
        Por el sólo hecho de ingresar, registrarse en el Sitio y aceptar electrónicamente los presentes Términos y Condiciones, los Usuarios aceptan que TELECOM podrá comunicarse con ellos a través del Sitio y/o por el email constituido. En ese sentido, el Usuario autoriza expresamente y sin reservas a TELECOM, a que esta última remita todas las notificaciones, facturas, comunicaciones y cualquier tipo de información enviada al Usuario, al email constituido en el formulario de registro, en donde se tomarán válidas todas las notificaciones y comunicaciones que desde y hacia allí se efectúen, en tanto el Usuario no notifique a TELECOM por escrito el cambio del mismo. El Usuario tiene la facultad de darse de baja de los servicios adquiridos a través del Sitio.
        <h4 class="mt-2">4. Capacidad.</h4>
        Para acceder al Sitio y a los servicios ofrecidos a través del mismo, se requiere tener capacidad legal para contratar. Es decir, no podrán acceder quienes carezcan de ella, los que hayan sido suspendidos o inhabilitados, ni los menores de edad. Los padres, tutores o responsables de los menores de edad o incapaces que utilicen el Sitio serán responsables por dicho uso, incluyendo cualquier cargo, facturación o daño que se derive de él.
        <h4 class="mt-2">5. Modificación de los Términos y Condiciones.</h4>
        Los Términos y Condiciones podrán ser sustituidos o sufrir modificaciones en cualquier momento y a exclusivo criterio de TELECOM, y no se requerirá a los Usuarios su consentimiento. TELECOM colocará un aviso en el Sitio alertando a los Usuarios sobre estos cambios, durante un tiempo razonable. Sin perjuicio de lo anterior, los Usuarios son responsables de leer estos Términos y Condiciones cada vez que ingresen al Sitio para ver si han sufrido modificaciones.
        <h4 class="mt-2">6. Interrupción del Servicio - Exclusión de responsabilidad.</h4>
        TELECOM se reserva el derecho de interrumpir, suspender o modificar en cualquier momento el Sitio y/o los servicios ofrecidos en el mismo, ya sea en forma permanente o transitoria. No se requerirá la conformidad de los Usuarios, ni será necesario aviso previo alguno. Asimismo, TELECOM no garantiza el acceso o uso permanente del Sitio, ya que éste podría interrumpirse por cuestiones técnicas ajenas a TELECOM. TELECOM no garantiza que el Sitio se encuentre libre de virus, gusanos o cualquier otro elemento que pueda llegar a dañar o alterar el normal funcionamiento de un ordenador. Es responsabilidad y obligación exclusiva del Usuario contar con las herramientas adecuadas para detectar, desinfectar y/o prevenir cualquier tipo de elementos y/o posibles daños de esta naturaleza. TELECOM no se responsabiliza por cualquier daño que pueda producirse en los equipos informáticos de los Usuarios o de terceros como consecuencia de la navegación del presente Sitio. Política de Privacidad de los Datos Personales suministrados por el Usuario. Seguridad y Tratamiento. Para poder utilizar el Sitio de manera eficiente y segura, los Usuarios deberán aportar ciertos datos sin los cuales se tornaría imposible brindar los Servicios Tics. Por eso se requiere que éstos sean verdaderos y exactos. Sera de aplicación lo dispuesto en la Política de Privacidad de TELECOM publicada https://institucional.telecom.com.ar/pdf/2018-06-06- Politica-de-privacidad.pdf
        <h4 class="mt-2">7. Veracidad de la Información suministrada.</h4>
        En caso de que la información o los datos suministrados por el Usuario no sean verdadera, éste será responsable por los daños que este hecho pudiera ocasionar.
        <h4 class="mt-2">8. Cookies.</h4>
        El Sitio puede utilizar un sistema de seguimiento mediante "cookies", para que el acceso a la información, al pasar de página en página, se realice con mayor rapidez. También ayuda en algunos casos a identificar a los Usuarios, sin necesidad de solicitarles la clave de acceso una y otra vez. Estas cookies son pequeños archivos que envía la página visitada y se alojan en el disco duro del ordenador, ocupando poco espacio. Se hace saber a los Usuarios que utilizando las opciones de su navegador podrán limitar o restringir según su voluntad el alojamiento de estas "cookies", aunque es desaconsejable restringirlas totalmente. El sistema podrá recoger información sobre sus preferencias e intereses. En el caso de que esto ocurra, la información será utilizada exclusivamente con fines estadísticos para mejorar los servicios que se prestan en el Sitio.
        <h4 class="mt-2">9. Disponibilidad y precio de los productos y/o servicios ofrecidos.</h4>
        Toda compra se encuentra sujeta a disponibilidad técnica y/o geográfica.
        <h4 class="mt-2">10. Prohibiciones.</h4>
        Se les prohíbe terminantemente a los Usuarios: a) enviar archivos o cualquier tipo de información cuyo contenido sea ilegal, obsceno, abusivo, difamatorio, injurioso o contrario a las buenas costumbres (la presente enumeración es meramente ejemplificativa); b) enviar archivos que contengan virus o cualquier otra característica capaz de dañar el funcionamiento de una computadora, del Sitio o del sistema; c) utilizar el Sitio para violar cualquier tipo de norma vigente; d) consignar datos falsos al momento de registrarse o realizar una compra, o cualquier otro momento en que les sea requerida cualquier tipo de información o datos personales; e) usar programas, software o aparatos automáticos o manuales para monitorear o copiar la información o cualquier tipo de contenido del Sitio sin previo consentimiento de TELECOM
        <h4 class="mt-2">11. Declaraciones.</h4>
        TELECOM no se hace responsable por la veracidad de la información incorporada al Sitio por terceros. Tampoco se hace responsable en cuanto haya sido reproducida o comunicada directamente por los Usuarios del Sitio sin verificación por parte de TELECOM Si algún Usuario se viera afectado por la información a la que se alude en el párrafo anterior, deberá comunicárselo a TELECOM, por mail o correo postal, a fin de que se proceda a la supresión de la misma.
        <h4 class="mt-2">12. Derechos reservados. Propiedad Intelectual.</h4>
        Todos los derechos del presente Sitio están reservados y corresponden a TELECOM El contenido del presente Sitio, incluyendo aunque no limitado al texto, logos, gráficos, y todo el diseño en general, así como su base de datos y software, es de propiedad de TELECOM o tiene derecho a usarlo en virtud de licencias de uso otorgadas y se encuentra protegido por las legislación nacional e internacional vigente sobre propiedad intelectual.
        <h4 class="mt-2">13. Razón social y domicilio.</h4>
        La razón social de la empresa es Telecom Argentina S.A., con domicilio en General Hornos N° 690, CABA.
        <h4 class="mt-2">14. Notificaciones.</h4>
        Todas las notificaciones y/o comunicaciones que deban efectuarse por el uso de Sitio bajo estos Términos y Condiciones, podrán realizarse: (i) al Usuario: mediante correo electrónico, a la cuenta de correo consignada por éste, o por carta documento, al domicilio de instalación de los Servicios; por SMS al número celular consignado por el Usuario, (ii) a TELECOM a su domicilio legal indicado en el punto anterior.
        <h4 class="mt-2">15. Avisos publicitarios y links.</h4>
        Cuando el Usuario hace "click" en avisos publicitarios o links de terceros e ingresa en otros sitios que no pertenecen a TELECOM, estará sujeto a los términos y condiciones de dichos sitios. El Usuario deberá leer detenidamente sus políticas de acceso y uso. TELECOM no garantiza la legalidad, actualidad, calidad ni utilidad de los contenidos, operaciones e informaciones que se comuniquen, reproduzcan y/o realicen en sitios enlazados de terceros ni la ausencia de nocividad de tales contenidos o servicios, por lo que el Usuario exime de toda responsabilidad a TELECOM por los contenidos incluidos en los referidos sitios o los servicios que en ellos se brindan o promocionan.
        <h4 class="mt-2">16. Jurisdicción y Ley aplicable.</h4>
        Los presentes Términos y Condiciones se encuentran regidos sin excepción y en todos sus puntos por las leyes de la República Argentina y serán interpretados de acuerdo a ellas. Ante cualquier diferencia, desacuerdo o conflicto derivado de la interpretación, validez, alcance y/o aplicación de los presentes Términos y Condiciones, los Usuarios se comunicarán con TELECOM de manera fehaciente, haciéndole llegar su reclamo, para que las partes traten de arribar a un acuerdo. En caso de que no sea posible arribar a una solución serán competentes los Tribunales ordinarios de la Capital Federal con competencia en la materia. Los servicios que presta Telecom Argentina S.A. están sujetos a disponibilidad técnica y geográfica dentro del territorio de la República Argentina.
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
      
    </div>

