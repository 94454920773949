import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, ObservableInput, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponseResult, IUserLogin } from '../shared/interfaces';
import { BaseService } from './base.service';
import { Error } from '../models/error';
import { loginErrors } from '../models/login-errors';
import { MetricsService } from '@b2b/gtm-library';
import { httpCodeErrorMapping } from '../models/http-code-error-mapping';

@Injectable()
export class AuthService extends BaseService {
  redirectUrl: string;
  isAuthenticated = true;

  constructor(private http: HttpClient, private service: MetricsService) {
    super();
    this.headers = this.headers.set('tab', 'auth');
  }

  login(userLogin: IUserLogin, token: string): Observable<boolean | Error> {
    if (!environment.local) {
      this.headers = this.headers
        .set('g-recaptcha-response', token)
        .set('persistent', 'true');
    }

    return this.http.post<any>(
      `${environment.loginUrl}/login`,
      userLogin,
      { headers: this.setHint('username', userLogin.username) }
    )
      .pipe(
        catchError(this.handleLoginError.bind(this))
      )
  }

  logout(): Observable<any> {
    return this.http.post(`${environment.loginUrl}/login/logout`, null, {
      withCredentials: true,
      headers: this.setHint('action', 'user logout')
    });
  }

  forgotPassword(email: string): Observable<IResponseResult> {
    return this.http.post<IResponseResult>(
      `${environment.loginUrl}/login/forgotpassword/` + email,
      null,
      {
        withCredentials: true,
        headers: this.setHint('email', email)
      }
    );
  }

  getAntiForgeryToken(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.loginUrl}/oauth/AntiForgeryToken`,
      {
        headers: new HttpHeaders({ hint: 'get antiforgery token' })
      }
    );
  }
  
  private handleLoginError(err: any): ObservableInput<Error> {
    const error = httpCodeErrorMapping[err.status] || loginErrors.default;

    this.service.sendErrorMetric(
      error.shortMessage,
      'user',
      error.code,
      err.status,
      error.shortMessage,
      'ingreso de credenciales',
      'login'
    );

    throw error;
  }
}
