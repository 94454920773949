import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  color: ThemePalette = 'primary';
  mode: MatProgressSpinnerModule= 'indeterminate';
  value = 100;
}
