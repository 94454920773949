import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { Environments, environment } from "src/environments/environment";
import { EnvironmentService } from "../services/environment.service";
import { RegisterService } from "../services/register.service";
import { ValidationService } from "../services/validation.service";

@Component({
  selector: "app-activated-user",
  templateUrl: "./activated-user.component.html",
  styleUrls: ["./activated-user.component.scss"],
})
export class ActivatedUserComponent {
  activatedUser = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private environmentService: EnvironmentService,
    private cookieService: CookieService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private registerService: RegisterService,
    private validationService: ValidationService,
  ) {
  }

  private get queryParams(): any {
    return this.activatedRoute && this.activatedRoute.snapshot && this.activatedRoute.snapshot.queryParams;
  }

  public get success(): boolean {
    return Boolean(this.queryParams && this.queryParams.success === 'true');
  }

  public get name(): string {
    return this.queryParams && this.queryParams.name;
  }

  public get lastName(): string {
    return this.queryParams && this.queryParams.lastName;
  }

  public get email(): string {
    return this.queryParams && this.queryParams.email;
  }

  public get token(): string {
    return this.queryParams && this.queryParams.token;
  }

  get virtual(): boolean {
    return Environments.Desktop.some((f: string) =>
      this.environmentService.currentEnvironment.includes(f)
    );
  }
  public async activate(): Promise<any> {
    const clientId = this.cookieService.get("clientId");
    this.spinner.show();
    try {
      await this.registerService.activateUser(this.email, this.token, clientId);
      this.spinner.hide();
      this.router.navigate(["/success"]);
    } catch (error) {
      this.spinner.hide();
      this.validationService.presentAlertMensaje({
        Imagen: "assets/images/icono-captcha-error.png",
        Subtitulo: "Te pedimos disculpas, no hemos podido completar la información en este momento. Por favor intentalo en unos minutos.",
        Titulo: "Ups, hubo un problema",
      })
      this.activatedUser = false;
    }
  }

  public goHome() {
    const clientId = this.cookieService.get("clientId");
    this.router.navigate(["/"], {
      queryParams: {
        redirectUrl: `${environment.loginUrl}/oauth/oauthcode?clientId=${clientId}`,
      },
    });
  }
}
