<ngx-spinner></ngx-spinner>

<div data-ui-section="Gol-Login" class="main">
  <router-outlet></router-outlet>
</div>

<div class="content-floating-button">
  <b2b-floating-button [targetUrl]="whatsappUrl" sendClickMetrics="loginHelp"></b2b-floating-button>
</div>

<lib-b2bgol-ux-footer></lib-b2bgol-ux-footer>

<app-custom-toast class="custom-top"></app-custom-toast>
