import { Component } from '@angular/core';

@Component({
  selector: 'app-register-error',
  templateUrl: './register-error.component.html',
  styleUrls: ['./register-error.component.scss']
})
export class RegisterErrorComponent {
  showAsesor(){
    const asesor: any = document.getElementsByClassName('help-text')[0]
    asesor.click();
  }
}
