import { MetricsPresets, Sections } from "@b2b/gtm-library";

export const metricsPresets: MetricsPresets = Object.freeze({
    loginFormButton: {
        section: Sections.Content,
        screen_name: 'ingreso de credenciales',
        flow: 'login'
    },
    whatsMyUser: {
        section: Sections.Content,
        component: '¿cual es mi usuario?',
        screen_name: 'ingreso de credenciales',
        element: 'cerrar',
        flow: 'login'
    },
    loginHelp: {
        section: Sections.Content,
        component: 'cinta de contacto flotante',
        screen_name: 'ingreso de credenciales',
        element: 'ayuda',
        flow: 'login'
    }
})