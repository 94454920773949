<div class="row">
  <div class="col-12 p-md-1 p-sm-2 p-xs-2">
    <div class="row paso_2 mx-1">
      <div class="col-12 mt-3 text-center logo-mobile">
        <img class="my-3" src="assets/images/telecom.svg" alt="" />
      </div>

    </div>
  </div>
</div>
<div class="row  mx-1">
  <div class="mx-auto mt-4 mobile">
        <div class="register-five-content">
            <div class="container-five" >
              <div class="box-img">

                <img class="img-send" src="assets/images/send.svg" alt="send email success" />
              </div>
              <p class="title-five">¡Casi Listo! Verificá tu email</p>
              <div class="box-subt">
                <p class="subtitle-five subtitle-space">¡Felicidades! Has completado con éxito el proceso de registro. En breve, recibirás un email para verificar tu cuenta.</p>
              </div>
              <hr class="mt-4 reset-hr" />
              <div class="box-subt">
                <p class="subtitle-five">¿No te llegó ningún email en la bandeja principal o spam?</p>
              </div>
              <div class="col-12 d-flex justify-content-center mb-3 mobile">
              <div class="box-resend" >
                <a *ngIf="showResendMail && timer === 59"
                (click)="resendMailRegister()" [attr.data-ui-element]="isClientGol ? 'Reenviar email gol':'Reenviar email col'"
                class="cursor-pointer text-left" [class.disabled]="sendingMail"
                >
                <small id="emailHelp" class="btnEmailHelp"
                  >Reenviar email</small
                >
              </a >

              <small class="emailResendHelp" *ngIf="timer === 59 && !showResendMail">Reenviar email en 1:00</small>
              <small class="emailResendHelp" *ngIf="timer >= 0 && timer < 59 ">Reenviar email en 0:{{ getTimeToString( limit - timer)}}</small>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>

<!-- <div class="col-12 p-md-1 p-sm-2 p-xs-2" data-ui-section-2="Paso 3: USUARIO Y CONTRASEÑA" *ngIf="this.isAdmin">
  <div class="row items text-center">
    <div>
      <img class="logo" src="assets/images/telecom.svg" alt="" />
    </div>
    <div class="col-12 mb-2">
      <h1 class="title text-center">Verificá tu casilla de email</h1>
    </div>
      <p class="sub-title text-center">
        Recibirás un email con un link para validar tu casilla y
        completar el proceso de registración, si no encontrás el email
        en tu bandeja de entrada verifica el correo no deseado.
      </p>
      <p class="sub-title text-center">
        Si encontrás algún problema en el proceso, contactate con nosotros a
        través del Asesor Online ubicado en la esquina inferior derecha.
      </p>
    <div class="col-12 mt-3 mb-2 text-center">
      <img class="mt-3 mb-4 successSendEmail" src="assets/images/send.png" alt="" />
    </div>
    <div class="col-12 text-center">
      <div class="form-group">
        Recibirás el email en la casilla {{ userSalesForce?.mail }}
      </div>
    </div>
    <div class="col-12 text-center">
      <div class="form-group">
          <button type="button"
            data-ui-element="Volver a enviar mail"
            (click)="resendMail()"
            *ngIf="isAdmin"
            class="btn login-btn w-80 content-btn-register-four"
            [disabled]="sendEmail">Reenviar email</button>
      </div>
    </div>
    <div class="col-12 text-center">

        <gol-button class="btn register-btn w-80 justify-content-center"
          data-ui-element="Volver a la poantalla principal"
          type="button" [routerLink]="['/']">
          Volver a la pantalla principal
        </gol-button>
    </div>
    </div>
</div>
<div class="col-12 p-md-1 p-sm-2 p-xs-2" data-ui-section-2="Paso 3: USUARIO Y CONTRASEÑA" *ngIf="!this.isAdmin">
  <div class="row items text-center">
    <div>
      <img class="logo" src="assets/images/telecom.svg" alt="" />
    </div>
    <div class="col-12 mb-2">
      <h1 class="title text-center">Tu administrador tiene que aprobar tu cuenta</h1>
    </div>
      <p class="sub-title text-center">
        Tu administrador recibirá un email en su casilla de correo, si no encuentra el
        email en su bandeja de entrada deberá verificar el correo no deseado.
      </p>
      <p class="sub-title text-center">
        Si hay algún problema en el proceso, contacte con nosotros a través del Asesor Online ubicado en la esquina inferior derecha.
      </p>
    <div class="col-12 mt-3 mb-2 text-center">
      <img class="mt-3 mb-4 successSendEmail" src="assets/images/key-user.png" alt="" />
    </div>
    <div class="col-12 text-center">
      <div class="form-group">
        Recibirás una copia del email y notificaciones sobre el estado del proceso en la casilla {{ userSalesForce?.mail }}
      </div>
    </div>
    <div class="col-12 text-center">

        <gol-button class="btn register-btn w-80 justify-content-center"
          data-ui-element="Volver a la pantalla principal"
          type="button" [routerLink]="['/']">
          Volver a la pantalla principal
        </gol-button>
    </div>
    </div>
</div> -->
