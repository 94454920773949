import { CommonModule } from "@angular/common";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { UnauthorizedComponent } from "./unauthorized.component";
import { NgxCaptchaModule } from "ngx-captcha";

const routes: Routes = [
  { path: "unauthorized", component: UnauthorizedComponent },
];

@NgModule({
  declarations: [UnauthorizedComponent],
  exports: [UnauthorizedComponent],
  imports: [
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UnauthorizedModule {}
