import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-forgot-success",
  templateUrl: "./forgot-success.component.html",
  styleUrls: ["./forgot-success.component.scss"],
})
export class ForgotSuccessComponent implements OnInit {
  constructor(private cookieService: CookieService, private router: Router) {}

  ngOnInit(): void {}
  public goToHome() {
    const clientId = this.cookieService.get("clientId");
    this.router.navigate(["/"], {
      queryParams: {
        redirectUrl: `${environment.loginUrl}/oauth/oauthcode?clientId=${clientId}`,
      },
    });
  }
}
