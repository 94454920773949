import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  ClientAddress,
  DataSalesForce,
  IResponseResult,
  ISalesForceInfo,
  ISalesForceInfoRequest,
  IUserRegistration,
  IUserRegistrationPayload,
} from '../shared/interfaces';
import { BaseService } from './base.service';


const initSalesForsObj = () => {
  return {
    apellido: '',
    cuit: '',
    id: '',
    mail: '',
    isClient: false,
    nombre: '',
    password: '',
    salesForceInfo: [],
    hasAdmin: false
  }
}

@Injectable()
// tslint:disable-next-line:directive-class-suffix
export class RegisterService extends BaseService {
  firstName: string;
  lastName: string;
  // Se muestra en la pantalla final a quien va destinado
  usermailTo: string;
  message: string;
  userRegistration: IUserRegistration;
  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isClienteGol = new BehaviorSubject<boolean>(false);
  contactsSalesForceInfo: ISalesForceInfo[];
  userSalesForce: ISalesForceInfo;
  userSalesForce$ = new BehaviorSubject<ISalesForceInfo>(initSalesForsObj());
  address: ClientAddress;
  saleForceInfoRequest!: ISalesForceInfoRequest;
  isAdmin: boolean;
  hasAdmin: boolean;
  listEmailsFiltered = [];
  listEmails = [];
  pageSize = 5;
  pageStart = 0;
  constructor(private http: HttpClient) {
    super();
    this.headers = this.headers.set("tab", "register");
  }

  registerValidateMailGOL(mail: string): Observable<ISalesForceInfo> {
    return this.http.post<ISalesForceInfo>(
      `${environment.signupUrl}/register/stepValidateMail`,
      null,
      {
        params: new HttpParams().set("mail", mail),
        withCredentials: true,
        headers: this.setHint("mail", mail),
      }
    );
  }

  registerValidateMailCOL(mail: string): Observable<ISalesForceInfo> {
    return this.http.post<ISalesForceInfo>(
      `${environment.signupUrl}/register/stepValidateMailCOL`,
      null,
      {
        params: new HttpParams().set("mail", mail),
        withCredentials: true,
        headers: this.setHint("mail", mail),
      }
    );
  }

  registerToStepTwo(cuic: string): Observable<DataSalesForce> {
    return this.http.post<DataSalesForce>(
      `${environment.signupUrl}/register/step1`,
      null,
      {
        params: new HttpParams().set(
          "cuic",
          cuic.split("-").join("").replace(/ /g, "")
        ),
        withCredentials: true,
        headers: this.setHint("cuic", cuic),
      }
    );
  }
  /**
   * https://gestiononline.telecom.com.ar/gestionapps/b2bgol-svc-200/b2bgol-11291/api
   */
  registerToStepThree(cuic: string, altura: string): Observable<any> {
    return this.http.post(
      `${environment.signupUrl}/register/step2`,
      null,
      {
        params: new HttpParams().set("cuic", cuic).set("altura", altura),
        withCredentials: true,
        headers: this.setHint("cuic", cuic),
      }
    );
  }

  registerToStepFive(model: IUserRegistrationPayload) {
    this.completeInfoModel(model);
    return this.http.post<IResponseResult>(
      `${environment.signupUrl}/register/step4`,
      model,
      {
        withCredentials: true,
        headers: this.setHint("email", model.email),
      }
    );
  }

  registerToStepFour(model: ISalesForceInfoRequest) {
    return this.http.post<ISalesForceInfo>(
      `${environment.signupUrl}/register/step2`,
      model,
      {
        withCredentials: true,
        headers: this.setHint("email", model.mail),
      }
    );
  }

  resendMailStepFour(model: ISalesForceInfoRequest) {
    return this.http.post<ISalesForceInfo>(
      `${environment.signupUrl}/register/resendMail`,
      model,
      {
        withCredentials: true,
        headers: this.setHint("email", model.mail),
      }
    );
  }
  registerToStepMailResend(model: ISalesForceInfoRequest) {
    return this.http.post<ISalesForceInfo>(
      `${environment.signupUrl}/register/resendMailRegister`,
      model,
      {
        withCredentials: true,
        headers: this.setHint("email", model.mail),
      }
    );
  }

  private completeInfoModel(model: IUserRegistrationPayload) {
    model.status = "A";
    model.cuit = this.userRegistration.cuic;
    model.client = model.cuit;
    model.address = this.userRegistration.calle || "";
  }
  public activateUser(
    email: string,
    token: string,
    clientId: string
  ): Promise<any> {
    return this.http
      .get(`${environment.loginUrl}/login/activateUser`, {
        params: {
          email,
          token,
          clientId,
        },
        headers: this.setHint("email", email),
      })
      .toPromise();
  }

  public addLastFirtsName(firstname: string, lastname: string) {
    this.firstName = firstname;
    this.lastName = lastname;
  }

  agregarCliente(user: ISalesForceInfo) {
    this.userSalesForce = user;
  }
  openWhatsapp() {
    window.open(environment.whatsappBaseUrl, "_blank");
  }
}
