import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-three',
  templateUrl: './register-three.component.html',
  styleUrls: ['../register.scss']
})
export class RegisterThreeComponent {

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected router: Router
  ) {
  }

  noAcepto() {
    this.router.navigateByUrl('/register-two');
  }
  acepto() {
    this.router.navigateByUrl('/register-four');
  }

}
