import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { EnvironmentService } from "../services/environment.service";
import { ForgotPasswordService } from "../services/forgot-password.service";

import Swal from "sweetalert2";
import { ValidationService } from "../services/validation.service";

export interface IForgotPasswordMixin {
  loginForm: UntypedFormGroup;
}

@Component({
  template: "",
})
export class ForgotPasswordMixin implements OnInit {
  public forgotPasswordGroup: UntypedFormGroup;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    protected forgotPasswordService: ForgotPasswordService,
    protected spinner: NgxSpinnerService,
    protected environmentService: EnvironmentService,
    protected validateService: ValidationService
  ) {}

  ngOnInit() {
    this.forgotPasswordGroup = this.formBuilder.group({
      password: ["", Validators.required],
      confirmationPassword: ["", Validators.required],
    });

    this.forgotPasswordGroup.setErrors(null);
  }

  public get password() {
    return this.forgotPasswordGroup.get("password");
  }

  public get confirmationPassword() {
    return this.forgotPasswordGroup.get("confirmationPassword");
  }

  private get queryParams(): any {
    return (
      this.activatedRoute &&
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.queryParams
    );
  }

  public get token(): string {
    return this.queryParams && this.queryParams.token;
  }

  public get name(): string {
    return this.queryParams && this.queryParams.name;
  }

  public get action(): string {
    return this.queryParams && this.queryParams.action;
  }

  public onSubmit() {
    if (!this.password.valid) {
      this.validateService.presentAlertMensaje({
        Imagen: "assets/images/icono-captcha-error.png",
        Subtitulo: "Debe ingresar todos los campos",
        Titulo: "Atención",
      });
      return;
    }
    if (this.password.value === this.confirmationPassword.value) {
      this.spinner.show();
      try {
        this.forgotPasswordService
          .updatePassword(
            this.name,
            this.password.value,
            this.token,
            this.action
          )
          .subscribe(
            (result) => {
              this.spinner.hide();
              this.router.navigate(["/forgot-success"]);
            },
            (err) => {
              this.spinner.hide();
              this.validateService.presentAlertMensaje({
                Imagen: "assets/images/icono-captcha-error.png",
                Subtitulo:
                  "Ha ocurrido un error al procesar la solicitud, por favor intentelo de nuevo más tarde.",
                Titulo: "Atención",
              });
            }
          );
      } catch (error) {
        this.spinner.hide();
        this.validateService.presentAlertMensaje({
          Imagen: "assets/images/icono-captcha-error.png",
          Subtitulo:
            "Ha ocurrido un error al procesar la solicitud, por favor intentelo de nuevo más tarde.",
          Titulo: "Atención",
        });
      }
    } else {
      this.spinner.hide();
      this.validateService.presentAlertMensaje({
        Imagen: "assets/images/icono-captcha-error.png",
        Subtitulo: "Las contraseñas deben de coincidir.",
        Titulo: "Atención",
      });
    }
  }
}
