<ngx-spinner
  bdOpacity="0.9"
  bdColor="#333"
  size="large"
  color="#fff"
  type="timer"
  [fullScreen]="true"
>
  <p style="color: white">Cargando...</p>
</ngx-spinner>

<div class="main-content">
  <header>
    <img class="logo" src="/fibercorp/images/logo.png" alt="" />
    <ul class="steps">
      <li class="completed"></li>
      <li class="completed"></li>
      <li class="completed"></li>
      <li class="completed"></li>
      <li class="active"></li>
    </ul>
    Activación
  </header>

  <div class="content">
    <div class="box box-register">
      <ng-container *ngIf="success; else error">
        <div class="row">
          <div class="col-xs-12">
            <div class="d-flex-block">
              <p>Nombre de usuario:</p>
              <strong class="value-activated">{{ name }}</strong>
            </div>
            <div class="d-flex-block">
              <p>Apellido de usuario:</p>
              <strong class="value-activated">{{ lastName }}</strong>
            </div>
            <div class="d-flex-block">
              <p>Email:</p>
              <strong class="value-activated">{{ email }}</strong>
            </div>
          </div>
        </div>
        <div class="form-group overflow-hidden" style="margin-top: 1em">
          <a (click)="activate()" class="btn btn-danger cursor-pointer w-100 pull-right"
            >ACTIVAR USUARIO</a
          >
        </div>
      </ng-container>
      <ng-template #error>
        <div class="row">
          <div class="col-xs-12 error">
            <p>
              No se pudo realizar la activación. Intentelo de nuevo más tarde.
            </p>
          </div>
        </div>
        <div class="form-group overflow-hidden" style="margin-top: 1em">
          <a (click)="goHome()" class="btn btn-danger w-100 pull-right"
            >Ir a inicio</a
          >
        </div>
      </ng-template>
    </div>
  </div>
</div>
