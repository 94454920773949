<div class="modalNotification">
  <img width="102" height="102" src="assets/images/icon-check.png" alt="">
  <div class="text">
    <br>
    <h2>
      Email reenviado
    </h2>
    <br>
    <p>
      Recibirás un email con un link para validar tu casilla y completar el proceso de registración, si no encontrás el email en tu bandeja de entrada verifica el correo no deseado.
    </p>
    <br>
    <div>
        <button mat-button class="primary-button" [mat-dialog-close]="true">Cerrar</button>
    </div>
  </div>

</div>
