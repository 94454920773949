import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appNumericOnly]",
})
export class NumericOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const numericValue = inputElement.value.replace(/[^0-9]/g, "");
    inputElement.value = numericValue;
  }
}
