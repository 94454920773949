import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class BaseService {
  protected headers = new HttpHeaders();

  protected setHint(key: string, value: any): HttpHeaders {
    return this.headers.set('hint', `${key}: ${value}`);
  }
}
