import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent {

  constructor(private cookieService: CookieService,private router: Router) { }

  public goToHome() {
    const clientId = this.cookieService.get("clientId");
    this.router.navigate(["/"], {
      queryParams: {
        redirectUrl: `${environment.loginUrl}/oauth/oauthcode?clientId=${clientId}`,
      },
    });
  }

}
