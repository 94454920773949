<div class="text-content">

  <img width="102" height="102" src="assets/images/icon-!.svg" alt="">
  <br>
 <h2>{{data.title}}</h2>
 <br>
  <div mat-dialog-content>
    <span class="">
      {{ data.content }}
    </span>
  </div>
  <br>
  <div class="dialog-button" mat-dialog-actions [align]="'center'">
    <button mat-button class="primary-button" [mat-dialog-close]="true">{{data.buttonText}}</button>
  </div>
</div>
