import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import { Router } from "@angular/router";
import { RegisterService } from "src/app/services/register.service";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-register-six",
  templateUrl: "./register-six.component.html",
  styleUrls: ["../register.scss"],
})
export class RegisterSixComponent {
  public registerForm: UntypedFormGroup;
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    public service: RegisterService,
    private cookieService: CookieService
  ) {}

  public get messageArr(): string[] {
    return this.service.message.split("ReplaceUserUI");
  }

  public get getSingleMessage(): string {
    return (this.messageArr && this.messageArr[0]) || "";
  }
  public get getTwoMessage(): string {
    return (this.messageArr && this.messageArr[1]) || "";
  }
  public redirectToHome() {
    const clientId = this.cookieService.get("clientId");

    this.router.navigate(["/"], {
      queryParams: {
        redirectUrl: `${environment.loginUrl}/oauth/oauthcode?clientId=${clientId}`,
      },
    });
  }
  public get getEmail(): string {
    let mail: string;
    this.service.userSalesForce$.pipe(filter(x => x !== undefined)).subscribe(data => {
      mail = data.mail;
    });
    return mail;
  }
}
