import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RegisterSixComponent } from "./register/register-six/register-six.component";
import { ActivatedUserComponent } from "./activated-user/activated-user.component";
import { AppWrapperComponent } from "./app-wrapper/app-wrapper.component";
import { RegisterFiveComponent } from "./register/register-five/register-five.component";
import { RegisterMessageComponent } from "./register/register-message/register-message.component";
import { RegisterSuccessComponent } from "./register/register-success/register-success.component";
import { ForgotSuccessComponent } from "./forgot-password/forgot-password-success/forgot-success.component";
import { RegisterErrorComponent } from "./register/register-error/register-error.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "register", component: AppWrapperComponent },
  { path: "register-five", component: RegisterFiveComponent },
  { path: "register-error", component: RegisterErrorComponent },
  { path: "register-six", component: RegisterSixComponent },
  { path: "activateUser", component: ActivatedUserComponent },
  { path: "message", component: RegisterMessageComponent },
  { path: "success", component: RegisterSuccessComponent },
  { path: "forgot-success", component: ForgotSuccessComponent },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
