  <div *ngIf="variant === 'primary'" class="d-flex flex-column ">
    <label *ngIf="label" class="label" [class.error-text]="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched">{{ label }} <span *ngIf="!tooltip">*</span> 
      <div *ngIf="tooltip" class="tooltip-container">
        <fa-icon  class="tooltip-icon" [icon]="faCircleQuestion"></fa-icon>
        <div class="tooltip-text">{{ tooltip }}</div>
      </div>
    </label>
    <input class="input-register"  
          [class.error-border]="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched"
          [class.no-info]="formGroup.get(formControlName).valid && formGroup.get(formControlName).touched"
          [placeholder]="placeholder || ''" 
          [(ngModel)]="inputValue" 
          (ngModelChange)="onInputChange($event)"
          [required]="required"
          [disabled]="isDisabled === true"
          [type]="type"
          (blur)="formGroup.get(formControlName).markAsTouched()"/>

    <span *ngIf="password" class="icon-eye-pass" (click)="toggleVisibility()">
      <fa-icon [icon]="hide ? faEyeSlash : faEye"></fa-icon>
    </span>

    <div *ngIf="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched" class="error-message mt-2">
      <img src="assets/images/fal_circle_xmark.svg" class="mr-1" alt="">
      {{ errorMessage }}
    </div>
  </div>

  <div *ngIf="variant === 'secondary'" class="d-flex flex-column ">
    <label *ngIf="label" class="label" [class.error-text]="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched">{{ label }} <span>*</span></label>
    <span class="area-code">{{ area }}</span>
    <input class="input-register " [ngClass]="{ 'secondary': variant === 'secondary'}"  
          [class.error-border]="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched"
          [class.no-info]="formGroup.get(formControlName).valid && formGroup.get(formControlName).touched"
          [placeholder]="placeholder || ''" 
          [(ngModel)]="inputValue" 
          (ngModelChange)="onInputChange($event)"
          [required]="required"
          [disabled]="isDisabled === true"
          [type]="type"  
          (blur)="formGroup.get(formControlName).markAsTouched()"/>

    <div *ngIf="formGroup.get(formControlName).invalid && formGroup.get(formControlName).touched" class="error-message mt-2">
      <img src="assets/images/fal_circle_xmark.svg" class="mr-1" alt="">
      {{ errorMessage }}
    </div>
  </div>
