import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogModalData } from "src/app/models/dialog.model";

@Component({
  selector: "app-popup-modal",
  templateUrl: "./popup-modal.component.html",
  styleUrls: ["./popup-modal.component.scss"],
})
export class PopupModalComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogModalData) {}
}
