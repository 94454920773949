<div
  class="toast_wrapper"
  [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>
<div class="content">

  <img src="assets/images/icon-success.png" alt="success" />
  <div class="content-items" *ngIf="toastService.data">
    <label class="content_text">
      {{ toastService.data.content }}
    </label>
    <span>
      <img src="assets/images/times-closed.png" alt="close" (click)="closeToast()"  />
    </span>
  </div>
</div>

<div
    class="progress_bar"
    [ngClass]="{ success: toastService.data && toastService.data.type === 1 }"
    #element
  ></div>
</div>
