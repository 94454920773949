import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-mail',
  templateUrl: './notification-mail.component.html',
  styleUrls: ['./notification-mail.component.scss']
})
export class NotificationMailComponent {
  constructor() { /* TODO document why this constructor is empty */  }
}
