<div class="container jumbotron mt-5">
  <div class="row">
    <div class="col-12">
      <h3>
        Desafortunadamente, usted no posee permisos suficientes para acceder a
        esta plataforma.
      </h3>
      <div>
        <h4>
          {{ currentMessage }}
        </h4>
      </div>
    </div>
  </div>
</div>
