import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-dropdown-dark-mode",
  templateUrl: "./dropdown-dark-mode.component.html",
  styleUrls: ["./dropdown-dark-mode.component.scss"],
})
export class DropdownDarkModeComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() label?: string | undefined;
  @Input() placeholder?: string;
  @Output() optionSelected = new EventEmitter<string>();

  selectedOption: string | undefined;
  isDropdownOpen = false;
  hasInfo: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSelect(option: string): void {
    this.selectedOption = option;
    this.toggleDropdown();
    this.hasInfo = true;
    this.optionSelected.emit(option);
  }

}
