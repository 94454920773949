import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'btn-spinner',
  templateUrl: './btn-spinner.component.html',
  styleUrls: ['./btn-spinner.component.scss']
})
export class BtnSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
