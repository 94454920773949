import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterService } from "src/app/services/register.service";
import { ValidationService } from "src/app/services/validation.service";
import { ISalesForceInfo } from "src/app/shared/interfaces";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ThemePalette } from "@angular/material/core";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "app-register-two",
  templateUrl: "./register-two.component.html",
  styleUrls: ["../register.scss"],
})
export class RegisterTwoComponent implements OnInit {
  clienteGol: boolean;
  selectedUser: ISalesForceInfo;
  public registerForm: UntypedFormGroup;
  selectedTab = 0;
  @Output() ChangeTab: EventEmitter<number> = new EventEmitter();
  @Input() Emails!: ISalesForceInfo[];
  listEmails = [];
  listEmailsFiltered = [];
  pageSize = 5;
  pageStart = 0;
  isLoading: boolean = false;
  isLoadingMails: boolean = false;
  mailValido: boolean = false;
  mailInvalido: boolean = false;
  color: ThemePalette = "primary";
  mode: MatProgressSpinnerModule = "indeterminate";
  value = 100;
  userSelected: ISalesForceInfo;
  faCircleCheck = faCircleCheck;
  showSpinner: boolean = false;
  isFocused: boolean;
  hasData: boolean;

  showToast: boolean = false;
  toastType: string = "warning";
  toastTitle: string = "";
  toastMessage: string = "";
  toastLink: boolean = false;
  errorCode: number;
  showLabel: boolean = true;
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    public service: RegisterService,
    private validationService: ValidationService,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {}

  ngOnInit() {
    this.showToast = false;
    this.mailInvalido = false;
    this.mailValido = false;
    this.service.isClienteGol.subscribe((data) => {
      this.clienteGol = data;
      console.log(data);
    });
    this.service.isClienteGol.subscribe((data) => (this.clienteGol = data));
    console.log(this.clienteGol);
    this.mailValido = false;
    this.registerForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zñA-ZÑ0-9._%+-]+@[a-zñA-ZÑ0-9.-]+\\.[a-zñA-ZÑ]{2,4}$"
          ),
        ],
      ],
    });
    this.getEmailsSalesForce();
  }

  processOld() {
    this.spinner.show();
    let file = this.listEmails
      .filter((element) => element !== undefined)
      .shift();
    const cuic = file.cuit;
    this.service.registerToStepTwo(cuic).subscribe(
      (data) => {
        this.spinner.hide();
        this.service.contactsSalesForceInfo = data.salesForceInfo;

        this.getEmailsSalesForce();
      },
      (_error) => {
        this.spinner.hide();
        this.validationService.presentRegistrationErrorAlert({
          Imagen: "assets/images/icono-captcha-error.png",
          Subtitulo:
            "Te pedimos disculpas, no hemos podido completar la información en este momento. Por favor intentalo en unos minutos. De persistir el error ponete en contacto con el soporte.",
          Titulo: "Ups, hubo un problema",
        });
      }
    );
  }
  next() {
    this.ChangeTab.emit(1);
  }
  back() {
    this.showToast = false;
    this.mailInvalido = false;
    this.mailValido = false;
    this.ChangeTab.emit(0);
  }

  onSelect() {
    this.userSelected = this.selectedUser;
  }

  processStepTwo() {
    if (this.userSelected.mail.length > 1) {
      this.service.userSalesForce$.next(this.userSelected);
      this.ChangeTab.emit(2);
    }
  }

  onRefresh() {
    this.paginationEmails();
  }

  getEmailsSalesForce() {
    this.service.listEmailsFiltered = [];
    this.service.listEmails = this.service.contactsSalesForceInfo;
    this.paginationEmails();
  }
  paginationEmails() {
    this.isLoadingMails = true;
    const start = this.service.pageStart * this.service.pageSize;
    if (start <= this.service.listEmails?.length) {
      this.service.listEmailsFiltered = this.service.listEmails?.slice(
        start,
        start + this.service.pageSize
      );
      this.service.pageStart++;
    }
    this.isLoadingMails = false;
  }

  goToLogin() {
    this.location.back();
  }
  getTextSelect() {
    return this.isLoadingMails
      ? "Recargando el listado"
      : "Seleccioná un email";
  }

  validateMail1() {
    this.showToast = false;
    this.showSpinner = true;
    this.mailInvalido = false;
    this.mailValido = false;
    let mail: string = this.registerForm.get(["email"]).value;
    this.service.registerValidateMailGOL(mail).subscribe(
      (data) => {
        this.userSelected = data;
        this.mailValido = true;
        this.showSpinner = false;
      },
      (_error) => {
        this.showSpinner = false;
        switch (_error.status) {
          case 404:
            this.errorMessageGestion();
            break;
          case 401:
            this.errorMessage();
            break;
          default:
            this.errorMessageGeneric();
        }
      }
    );
  }

  async validateMail() {
    try {
      this.mailInvalido = false;
      this.mailValido = false;
      this.showToast = false;
      this.showSpinner = true;
      const mail: string = this.registerForm.get(["email"]).value;
      let data = null;
      if (this.clienteGol) {
        data = await this.service.registerValidateMailGOL(mail).toPromise();
      } else {
        data = await this.service.registerValidateMailCOL(mail).toPromise();
      }
      this.showSpinner = false;
      this.userSelected = data;
      this.mailValido = true;
    } catch (error) {
      this.showSpinner = false;
      this.errorCode = error.status;
      console.log(error.status);

      switch (error.status) {
        case 404:
          this.errorMessageGestion();
          break;
        case 401:
          this.errorMessage();
          this.showLabel = false;
          break;
        default:
          this.errorMessageGeneric();
      }
    }
  }

  onInputFocus() {
    this.isFocused = true;
  }

  onInputBlur() {
    this.isFocused = false;
  }
  onInputValueChanged(event: Event) {
    this.mailInvalido = false;
    this.mailValido = false;
    const inputValue = (event.target as HTMLInputElement).value;
    this.hasData = inputValue.trim() !== "";
  }

  openWhatsapp() {
    this.service.openWhatsapp();
  }

  public errorMessageGestion() {
    this.mailInvalido = true;
    this.showToast = true;
    this.toastTitle = "Email no asociado al CUIT";
    this.toastMessage = `Tu email no tiene asociación previa al CUIT de tu empresa. Por favor comunicate con el <span class="link">asesor&nbsp;online</span> para más información.`;
    this.toastLink = true;
  }

  public errorMessageGeneric() {
    this.showToast = true;
    this.toastTitle = "";
    this.toastMessage =
      "¡Ups! Algo inesperado ocurrió, por favor intentá de nuevo más tarde";
    this.toastLink = false;
  }

  public errorMessage() {
    this.mailInvalido = true;
    this.showToast = true;
    this.toastTitle = "Email registrado";
    this.toastMessage = `El email que ingresaste ya esta registrado. ¿Olvidaste tu contraseña? <span class="link">Recuperala&nbsp;aquí</span>`;
    this.toastLink = true;
  }

  resetPassword() {
    this.router.navigateByUrl("/reset-password");
  }
}
