import { CommonModule } from "@angular/common";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerModule } from "ngx-spinner";
import { EnvironmentService } from "../services/environment.service";
import { ActivateUserRoutingModule } from "./activate-user-routing.module";
import { ActivateUserComponent } from "./activate-user.component";

@NgModule({
  declarations: [ActivateUserRoutingModule.components, ActivateUserComponent],
  exports: [ActivateUserComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxCaptchaModule,
    ActivateUserRoutingModule,
    NgxSpinnerModule,
  ],
  providers: [
    CookieService,
    EnvironmentService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class ActivateUserModule {}
