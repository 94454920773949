import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import Swal from "sweetalert2";
import { Modal } from "../models/modal";
import { environment } from "src/environments/environment";

@Injectable()
export class ValidationService {
  public title = "Atención";
  public body: string;
  public messageError: string;
  public firstNameAndLastnamePattern: string = '^[a-zA-Z áéíóúÁÉÍÓÚÑñ \-\']+';
  setTitle(title: string) {
    this.title = title;
  }

  setBodyAndShow(body: string) {
    this.messageError = body || "";
    this.show();
  }
  closeBodyAndShow() {
    Swal.close();
  }
  private show() {
    Swal.fire(this.title, this.messageError, "info");
  }

  public showSuccess(title: string, message: string) {
    Swal.fire(title, message, "success");
  }

  public showError(title: string, message: string) {
    Swal.fire(title, message, "error");
  }

  public presentAlertMensaje(modal: Modal) {
    Swal.fire({
      imageUrl: modal.Imagen,
      title: modal.Titulo,
      text: modal.Subtitulo,
      html: modal.html,
      width: 854,
      padding: "3em",
      background: "#121212",
      showCloseButton: true,
      backdrop: "rgba(0, 0, 0, 0.6)",
      showConfirmButton: false,
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
    });
  }

  public presentTyc(modal: Modal) {
    Swal.fire({
      html: modal.html,
      background: "black",
      showCloseButton: true,
      backdrop: "black",
      showConfirmButton: false,
      customClass: {
        container: "tyc_modal_container",
        popup: "tyc_modal_popup",
        closeButton: "tyc_modal_close-btn",
        content: "tyc_modal_content",
      },
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
    });
  }

  public presentRegistrationErrorAlert(modal: Modal) {
    Swal.fire({
      imageUrl: modal.Imagen,
      title: modal.Titulo,
      text: modal.Subtitulo,
      html: modal.html,
      width: 500,
      padding: "3em",
      background: "black",
      showCloseButton: true,
      backdrop: "black",
      confirmButtonText: modal.buttonText ? modal.buttonText : "Ok",
      showCancelButton: true,
      cancelButtonText: "Contactanos por Whatsapp",
      customClass: {
        confirmButton: "btn swal-login-btn",
        cancelButton: "btn swal-register-btn",
        content: "error_modal_content",
        container: "error_modal_container",
        popup: "error_modal_popup",
      },
      buttonsStyling: false,
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        window.open(environment.whatsappBaseUrl, `_blank`);
      }
    });
  }

  public presentAlertMensajeRedirect(modal: Modal) {
    Swal.fire({
      imageUrl: modal.Imagen,
      title: modal.Titulo,
      text: modal.Subtitulo,
      html: modal.html,
      width: 500,
      padding: "3em",
      background: "black",
      showCloseButton: true,
      backdrop: "black",
      focusConfirm: false,
      confirmButtonText: modal.buttonText ? modal.buttonText : "Ok",
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
      onOpen: (swal) => {
        $(swal)
          .find("#idLink")
          .click(function () {
            Swal.close();
          });
      },
    });
  }
  public presentErrorMessage(modal: Modal) {
    Swal.fire({
      imageUrl: modal.Imagen,
      title: modal.Titulo,
      text: modal.Subtitulo,
      html: modal.html,
      width: 500,
      padding: "3em",
      background: "black",
      backdrop: "black",
      showConfirmButton: false,
      showCloseButton: true,
      confirmButtonText: modal.buttonText ? modal.buttonText : "Ok",
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
      onOpen: (swal) => {
        $(swal)
          .find("#idLink")
          .click(function () {
            Swal.close();
          });
      },
    });
  }
  public showMessageAlert(modal: Modal) {
    Swal.fire({
      imageUrl: modal.Imagen,
      imageWidth: 80,
      title: modal.Titulo,
      text: modal.Subtitulo,
      html: modal.html,
      width: 560,
      padding: "1em",
      background: "#202020",
      showCloseButton: false,
      backdrop: "rgba(0, 0, 0, 0.01)",
      showConfirmButton: true,
      confirmButtonText: modal.buttonText,
      onAfterClose: () => {
        if (modal.RedirectTo) {
          // Redirecciona
          window.location.href = modal.RedirectTo;
        }
      },
    });
  }
  validateOnlyLetters() {
    return (control: AbstractControl) => {
      const value = <string>control.value;
      const exp = new RegExp(/^[A-Za-z áéíóúÁÉÍÓÚÑñ \s]+$/g);
      const respuesta = exp.test(value);
      if (!respuesta) {
        return {
          errorCaracteresSoloL: 'Solo ingresar Letras.'
        };
      }
      return null;
    };
  }
  public isFieldOneEqualFieldTwo( field1: string, field2: string ) {

    return ( formGroup: AbstractControl ): ValidationErrors | null => {

      const passwordControl = formGroup.get(field1);
      const confirmationPasswordControl = formGroup.get(field2);

      const passwordValue = passwordControl?.value;
      const confirmationPasswordValue = confirmationPasswordControl?.value;

      if (!passwordValue || !confirmationPasswordValue) {
        // Si alguno de los campos está vacío, retorna el error requerido
        return { required: true };
      }

      if (passwordValue !== confirmationPasswordValue) {
        // Si los campos no coinciden, establece el error 'notEqual'
        confirmationPasswordControl?.setErrors({ notEqual: true });
        return { notEqual: true };
      } else {
        // Si los campos coinciden, elimina los errores
        confirmationPasswordControl?.setErrors(null);
        return null;
      }
    };
  }

}

