import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterService } from "src/app/services/register.service";
import { ISalesForceInfo, ISalesForceInfoRequest } from "src/app/shared/interfaces";
import { environment } from "src/environments/environment";
import { filter, takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationMailComponent } from "src/app/components/notification-mail/notification-mail.component";
import { ErrorPageGolComponent } from "src/app/custom-error/error-page-gol/error-page-gol.component";
import { Subject, timer } from "rxjs";
import { ToastService } from "src/app/services/toast.service";
const LIMIT_VALUE = 59;
@Component({
  selector: "app-register-five",
  templateUrl: "./register-five.component.html",
  styleUrls: ["../register.scss","./register-five.component.scss"],
})
export class RegisterFiveComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  public validateCaptcha: boolean;
  public siteKey: string;
  selectedTab = 0;
  hide = false;
  userSalesForce: ISalesForceInfo;
  saleForceInfoReques: ISalesForceInfoRequest;
  sendEmail=false;
  isAdmin= false;
  public timer: number = 0;
  public limit = 59;
  public showResendMail = true;
  public count_send = 1;
  public sendingMail = false;
  private destroy = new Subject();
  private rxjsTimer = timer(1000, 1000);
  @Output() ChangeTab: EventEmitter<number> = new EventEmitter();
  isClientGol = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    private service: RegisterService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<NotificationMailComponent>,
    private dialog: MatDialog,
    protected toastService: ToastService
  ) { }

  async ngOnInit() {
    this.siteKey = environment.siteKey;
    this.getUserSalesForce();
  }

  back() {
    this.ChangeTab.emit(1);
  }

  getUserSalesForce() {
    this.spinner.show();
    this.service.userSalesForce$.pipe(filter(x => x !== undefined)).subscribe(data => {
      this.userSalesForce = data;
      this.isAdmin = this.service.isAdmin;
    });
    this.spinner.hide();
    this.initTimer();
    this.service.isClienteGol.subscribe(data=> this.isClientGol = data );
  }
  resendMail(){
    this.sendEmail = true;
    this.spinner.show();
    this.service.resendMailStepFour(this.service.saleForceInfoRequest).subscribe({
      error:(error) => {
        this.openErrorDialog();
        this.spinner.hide();
        this.sendEmail = false;
      },
      complete: () =>{
        this.openDialog();
        this.spinner.hide();
        this.sendEmail = false;
      }
    });

  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'dialog-notification';
    dialogConfig.height= '480px';
    dialogConfig.width= '580px';
    this.dialog.open(NotificationMailComponent,dialogConfig);
  }
  openErrorDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'dialog-error';
    dialogConfig.height= '570px';
    dialogConfig.width= '850px';
    this.dialog.open(ErrorPageGolComponent,dialogConfig);
  }
  getTimeToString(value: number):string {
    if(value >= 0 && value <10) {
      return `0${value}`;
    } else {
      if( value > 0 ){
        return value.toString();
      }
    }
    return '00';
  }
  resendMailRegister() {
    this.sendingMail = true;
    const model = this.service.saleForceInfoRequest;
    this.service.registerToStepMailResend(model).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.success || data.Success) {
          this.showResendMail = false;
          if (this.count_send > 0) {
            this.toastService.initiate({title: 'Succes', content: 'Email reenviado con éxito'});
          }
          this.count_send += 1;
          this.sendingMail = false
          this.initTimer();
        } else{
          this.router.navigate(["/register-error"]);
          this.sendingMail = false
          this.spinner.hide();
        }
      },
      (_error) => {
        this.router.navigate(["/register-error"]);
        this.sendingMail = false
        this.spinner.hide();
      }
    );
  }
  initTimer(){
    this.destroy.next(true);
    this.destroy.complete();
    this.destroy = new Subject();
    this.rxjsTimer = timer(1000,1000);
    this.rxjsTimer.pipe(takeUntil(this.destroy)).subscribe(val => {
      this.timer = val;
      if (this.timer === LIMIT_VALUE) {
        this.showResendMail = true;
        this.timer = 59;
        this.destroy.next(true);
        this.destroy.complete();
      }
    })
  }
}
