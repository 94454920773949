import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IForgotPassword, IResendInvitation } from '../shared/interfaces';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ForgotPasswordService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super();
    this.headers = this.headers.set('tab', 'forgotpassword');
  }

  public updatePassword(
    userName: string,
    confirmationPassword: string,
    token: string,
    action: string = ''
  ): Observable<any> {
    return this.httpClient.post<IForgotPassword>(`${environment.loginUrl}/login/changepassword`, {
      userName,
      newPassword: confirmationPassword,
      token,
      action,
    },
    { headers: this.setHint('username', userName) });
  }

  public resendInvitationEmail(userName: string): Observable<any> {
    return this.httpClient.post<IResendInvitation>(`${environment.loginUrl}/login/resendemail`, {
      userName,
    },
    { headers: this.setHint('username', userName) });
  }

}
