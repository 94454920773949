export const loginErrors = Object.freeze({
    inactiveUser: {
        code: 1,
        severity: 'warning',
        title: 'Usuario inactivo',
        shortMessage: 'usuario inactivo',
        message: 'Para más información, por favor comunicate con el administrador de cuenta de tu empresa.'
    },
    invalidUserOrPassword: {
        code: 2,
        severity: 'error',
        title: '',
        shortMessage: 'email o contraseña invalidos',
        message: 'Email o contraseña inválidos'
    },
    unknownUser: {
        code: 3,
        severity: 'warning',
        title: 'Usuario inexistente',
        shortMessage: 'usuario inexistente',
        message: 'Este usuario no está asociado a ninguna cuenta. Por favor, revisá tus datos o registrate.'
    },
    default: {
        code: 4,
        severity: 'warning',
        title: '',
        shortMessage: 'error inesperado',
        message: '¡Ups! Algo inesperado ocurrió, por favor intentá de nuevo más tarde.'
    }
});