<div class="row">
    <div class="col-12 p-md-1 p-sm-2 p-xs-2">
        <div class="col-12 mt-3 text-center logo-mobile">
            <img class="mt-3" src="assets/images/telecom.svg" alt="" />
        </div>
        <div class="row mx-1">
            <div class="mx-auto mt-4 mobile">
                <div class="login-content">
                    <div class="col-12 text-center icono-mobile">
                        <img class="img-password" src="assets/images/password.svg" alt="" />
                    </div>
                    <div class="col-12 text-center mobile">
                        <label class=" titulo">¡Listo!</label>
                        <label class="subtitulo">Generaste una nueva contraseña.</label>
                        <div class="col-12 text-center btn-home">
                            <button type="button" data-ui-element="Iniciar sesión" (click)="goToHome()"
                                class="btn home-btn w-80">Iniciar sesión</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>