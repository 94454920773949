import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ReCaptchaV3Service } from "ngx-captcha";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../services/auth.service";
import { EnvironmentService } from "../services/environment.service";
import { ForgotPasswordService } from "../services/forgot-password.service";
import { ToastService } from "../services/toast.service";
import { ValidationService } from "../services/validation.service";
import { LoginMixin } from "../shared/login-mixin";
import { MetricsService } from "@b2b/gtm-library";

@Component({
  selector: "app-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrls: ["./unauthorized.component.css"],
})
export class UnauthorizedComponent extends LoginMixin implements OnInit {
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    protected validateService: ValidationService,
    protected activatedRoute: ActivatedRoute,
    protected authService: AuthService,
    protected forgotPasswordService: ForgotPasswordService,
    protected environmentService: EnvironmentService,
    protected cookieService: CookieService,
    protected spinnerService: NgxSpinnerService,
    protected recaptchaV3Service: ReCaptchaV3Service,
    protected toastService: ToastService,
    protected metricsService: MetricsService
  ) {
    super(
      document,
      formBuilder,
      router,
      validateService,
      activatedRoute,
      authService,
      forgotPasswordService,
      environmentService,
      cookieService,
      spinnerService,
      recaptchaV3Service,
      toastService,
      metricsService
    );
  }

  public get message(): string {
    return this.queryParams.message || "";
  }

  public get currentMessage(): string {
    return this.message &&
      this.message !== "" &&
      this.message.includes("invalidPassword")
      ? "Contraseña invalida."
      : "Por favor, contáctese con un administrador o soporte para obtener más información al respecto.";
  }

  ngOnInit() {
    // Detectamos el ambiente
    this.document.body.classList.add(this.desktop ? "virtual" : "desktop");
  }
}
