import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModalData } from 'src/app/models/dialog.model';

@Component({
  selector: 'app-register-message',
  templateUrl: './register-message.component.html',
  styleUrls: ['./register-message.component.scss']
})
export class RegisterMessageComponent {
  @Output() ChangeTab: EventEmitter<number> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<RegisterMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModalData) { }

  processStepOne(recovery:boolean, selectEmail: boolean){
    this.dialogRef.close({recoveryPassword: recovery,selectEmail: selectEmail})
  }

}
