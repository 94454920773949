import { Injectable } from '@angular/core';
import { DialogModalData } from '../models/dialog.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { RegisterMessageComponent } from '../register/register-message/register-message.component';
import { NotificationModalComponent } from '../components/notification-modal/notification-modal.component';
import { PopupModalComponent } from '../components/popup-modal/popup-modal.component';

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}
  openDialog(data: DialogModalData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = data.panelClass;
    dialogConfig.height = data.height;
    dialogConfig.width = data.width;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    return this.dialog.open(NotificationModalComponent, dialogConfig);
  }
  openDialogPopUp(data: DialogModalData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = data.panelClass;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    return this.dialog.open(PopupModalComponent, dialogConfig);
  }
  openDialogRegister(data: DialogModalData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = data.panelClass;
    dialogConfig.height = data.height;
    dialogConfig.width = data.width;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    return this.dialog.open(RegisterMessageComponent, dialogConfig);
  }
}
