import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class ClientService {
  constructor(protected router: ActivatedRoute, protected navigator: Router) {}

  public get queryParams(): any {
    return (
      this.router && this.router.snapshot && this.router.snapshot.queryParams
    );
  }

  public get clientIdByCookie(): string {
    return this.getCookie("clientId");
  }

  public get clientId(): string {
    const redirectUrl: string =
      this.queryParams &&
      this.queryParams.redirectUrl &&
      this.queryParams.redirectUrl.split("?")[1];

    let clientId: string = redirectUrl && redirectUrl.split("&")[0];

    clientId =
      clientId &&
      clientId
        .replace("clientId=", "")
        .replace("clientId", "")
        .replace("clientid=", "")
        .replace("clientid", "");

    return clientId || this.queryParams.clientId || this.queryParams.clientid;
  }

  private getCookie(name: string): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }

    return null;
  }
}
