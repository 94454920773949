import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page-gol',
  templateUrl: './error-page-gol.component.html',
  styleUrls: ['./error-page-gol.component.scss']
})
export class ErrorPageGolComponent {


}
